
import { defineComponent } from "vue";
import * as bootstrap from "bootstrap";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { mapState, mapActions } from "vuex";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Actions } from "@/store/enums/StoreEnums";
import ExpiringRequestModal from "@/components/modals/ExpiringRequestModal.vue";
export default defineComponent({
  data() {
    return {
      toastOpened: false,
      lastCount: 0,
      notifications: [],
      showExpiringModal:false
    };
  },
  methods: {
    handleDismiss(notification) {
      ApiService.put("notifications", { notificationId: notification.id }).then(
        () => {
          this.updateNotifications();
        }
      );
    },
    updateNotifications() {
      if (store.getters.isUserAuthenticated) {
        ApiService.get("notifications").then((result) => {
          this.notifications = result.data.data;
          if (this.notifications.length > this.lastCount && !this.toastOpened) {
            this.lastCount = this.notifications.length;
            this.toastOpened = true;
            Swal.fire({
              position: "top-right",
              showCloseButton: true,
              icon: "info",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title:
                "Tiene " +
                this.notifications.length +
                " notificaciones sin leer.",
              toast: true,
            }).then(() => {
              this.toastOpened = false;
            });
          }
        });
      }
    },
    openNotificationsModal() {
      this.showExpiringModal = true;
    },
    // Si quisieras cerrarlo manualmente desde aquí:
    closeNotificationsModal() {
      this.showExpiringModal = false;
    },
  },
  mounted() {
    this.updateNotifications();
  },

  computed: {
    currentUser() {
      return store.state.AuthModule.user;
    },
    expiringNotifications() {
    // Ajusta la lógica según la estructura real de tus notificaciones
    return  48;
  },
  },
  name: "topbar",
  components: {
    KTUserMenu,
    KTNotificationsMenu
  },
});
