
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "KTNotificationsMenu",
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  setup() {
    
    const router = useRouter();

    const goToSolicitud = (id) => {
    console.log(id)
      router.push({ name: "mis_solicitudes", query: { request: id, t: Date.now()  } });
    };

    const goToAllSolicitudes = () => {
      router.push('/mis_solicitudes');
    };

    return {
      goToSolicitud,
      goToAllSolicitudes,
    };
  },
});
